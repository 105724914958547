import { useCallback, useEffect, useRef } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { Address } from "../../types/Location";
import "@reach/combobox/styles.css";
import { parseAddressComponents } from "../../utils/VendorFinderUtils";
import { GeoAltFill } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import CustomMapStyle from "./CustomMapStyle";
import { VendorLocation } from "../../types/Vendor";
import { getIconUrl } from "../../utils/VendorFinderUtils";
import { formatPhoneNumber } from "../../utils/Utils";
import "./Maps.css";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "10px",
};

const Regina = {
  lat: 50.44,
  lng: -104.61,
};
export function MapFinder(props: any) {
  const options: any = {
    styles: CustomMapStyle,
    disableDefaultUI: true,
    zoomControl: true,
  };

  const mapRef = useRef<google.maps.Map | null>();
  const onMapLoad = useCallback((map: google.maps.Map | null) => {
    mapRef.current = map;
  }, []);

  const onClickGeoLocator = useCallback((map: any) => {
    props.setErrorMessage("");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
        getAndSaveAddress(lat, lng);
      });
    } else {
      props.setErrorMessage("Geo Location is not embeded in browser");
    }
  }, []);

  useEffect(() => {
    if (props.vendorLocationList.length > 0)
      mapRef?.current?.setZoom(props.zoomMap);
  }, [props.zoomMap, props.vendorLocationList]);

  useEffect(() => {
    if (props.selectedLocation.latitude) {
      mapRef?.current?.panTo({
        lat: props.selectedLocation.latitude,
        lng: props.selectedLocation.longitude,
      });
    }
  }, [props.selectedLocation]);

  const setPinLocation = useCallback((map: any) => {
    props.setErrorMessage("");

    var lat = map.latLng.lat();
    var lng = map.latLng.lng();
    getAndSaveAddress(lat, lng);
  }, []);

  return (
    <>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={Regina}
        zoom={10}
        onClick={setPinLocation}
        onLoad={onMapLoad}
        options={options}
      >
        <div className="mapOverElements">
          <Button variant="light" id="geo-locator" onClick={onClickGeoLocator}>
            <GeoAltFill size={30} />
            <div className="divFontLocation">
              <div>
                <b>
                  <i>Find</i>
                </b>
              </div>
              <div>
                <b>
                  <i>Me!</i>
                </b>
              </div>
            </div>
          </Button>
        </div>
        {props.selectedLocation.latitude && (
          <Marker
            key={`${props.selectedLocation.name}-${props.selectedLocation.latitude}-${props.selectedLocation.longitude}SelectedPin`}
            position={{
              lat: props.selectedLocation.latitude,
              lng: props.selectedLocation.longitude,
            }}
            icon={{
              url: "/vendorIcons/map-marker-pin.svg",
              scaledSize: new window.google.maps.Size(60, 60),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(30, 60),
            }}
            draggable={true}
            onDragEnd={setPinLocation}
          />
        )}

        {props.vendorLocationList.map((loc: VendorLocation) => {
          return (
            <>
              <Marker
                key={`${loc.name}-${loc.lat}-${loc.lng}Pin`}
                position={{ lat: loc.lat, lng: loc.lng }}
                icon={{
                  url: "/vendorIcons/map-marker-solid.svg",
                  scaledSize: new window.google.maps.Size(44, 44),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(22, 44),
                }}
                onClick={() => props.setShowInfo(loc)}
              />
              <Marker
                key={`${loc.name}-${loc.lat}-${loc.lng}Icon`}
                position={{ lat: loc.lat, lng: loc.lng }}
                icon={{
                  url: getIconUrl(props.vendorTypeSelected),
                  scaledSize: new window.google.maps.Size(24, 24),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(12, 39),
                }}
                onClick={() => props.setShowInfo(loc)}
              />
            </>
          );
        })}

        {props.showInfo.lat !== 0 && props.showInfo.lng !== 0 && (
          <InfoWindow
            position={{ lat: props.showInfo.lat, lng: props.showInfo.lng }}
            onCloseClick={() => props.setShowInfo({ lat: 0, lng: 0, name: "" })}
          >
            <div className="markerName">
              {props.showInfo.name.toLowerCase()}
              <div className="vendorListAddress">
                {props.showInfo.streetAddress.toLowerCase()}
                <div>
                  {props.showInfo.postalCode}
                  {", "} {props.showInfo.city.toLowerCase()}
                </div>
              </div>
              <div className="vendorListContact">
                <div>
                  {props.showInfo.phoneNumber &&
                    props.showInfo.phoneNumber !== "" &&
                    formatPhoneNumber(props.showInfo.phoneNumber)}
                </div>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </>
  );

  function getAndSaveAddress(lat: number, lng: number) {
    var latlng = new google.maps.LatLng(lat, lng);

    // This is making the Geocode request
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (results, status) => {
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status === google.maps.GeocoderStatus.OK) {
        var addrComp = results![0].address_components;
        var addressData: Address = parseAddressComponents(addrComp);

        props.setShowInfo({ lat: 0, lng: 0, name: "" });

        props.setSelectedLocation({
          address: { ...addressData },
          formattedAddressFull: results![0].formatted_address,
          locationTypes: results![0].types,
          placeId: results![0].place_id,
          latitude: lat,
          longitude: lng,
          isSelectedByPin: true,
        });
      } else {
        props.setErrorMessage(
          "Error retrieving location data, please enter a new location"
        );
      }
    });
  }
}

export default MapFinder;
