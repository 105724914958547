import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { useTypedSelector } from "../../redux/store";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/loginActions";
import { Role } from "../../types/MockedUserTypes";
import { useAccount, useMsal, useIsAuthenticated } from "@azure/msal-react";

export function HeaderDropDownButton() {
  const navigate = useNavigate();
  const login = useTypedSelector((state) => state.login);
  const dispatch = useDispatch();
  const loggedInAsInjury = login.signedIn && login.role === Role.Injury;
  const loggedInUnauthorized = login.signedIn && login.role === Role.None;

  const { instance, accounts } = useMsal();

  const isAADAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0] || {});

  const handleSelect = (event: any) => {
    switch (event) {
      case "1":
        navigate("/");
        break;
      case "3":
        dispatch(logout());

        if (isAADAuthenticated) {
          instance.logout();
        }
        break;
      default:
        break;
    }
  };
  return (
    <>
      {(loggedInUnauthorized || loggedInAsInjury) && (
        <DropdownButton
          className="header-btn"
          data-testid="headerLoginDropDown"
          title={login.username}
          variant="outline-primary"
          onSelect={handleSelect}
        >
          <Dropdown.Item eventKey="3" data-testid="LogoutOption">
            Logout
          </Dropdown.Item>
        </DropdownButton>
      )}
    </>
  );
}
