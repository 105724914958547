import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { ModalFeedback } from "../Modals/ModalFeedback";
import "./Footer.css";
import { useTypedSelector } from "../../redux/store";
import { ToastSuccess } from "../Toast/ToastSuccess";

function isAuthenticated(login: any): boolean {
  return login.signedIn;
}

export function Footer() {
  const [modalShow, setModalShow] = useState(false);
  const reduxLogin = useTypedSelector((state) => state.login);
  const [loggedIn, setLoggedIn] = useState(false);
  const showFeedbackButton = false;
  const [accountType, setAccountType] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const emptyStringArray: string[] = [];
  const [successMessages, setSuccessMessages] = useState(emptyStringArray);

  useEffect(() => {
    async function updateLoginStatus() {
      switch (String(reduxLogin.role)) {
        case "Employee":
          setAccountType("SGIEmployee");
          break;
        case "Vendor":
          setAccountType("Vendor");
          break;
        default:
          setAccountType("None");
          break;
      }
      setEmail(reduxLogin.email);
      setName(reduxLogin.fullName);
      if (isAuthenticated(reduxLogin) === true) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    }
    updateLoginStatus();
  }, [reduxLogin, reduxLogin.role, reduxLogin.username]);

  useEffect(() => {
    if (toastMessage !== "") {
      let success = [];
      success.push(toastMessage);
      setSuccessMessages([...success]);
      setToastMessage("");
    }
  }, [toastMessage]);

  return (
    <>
      <ToastSuccess
        title="Feedback Sent"
        setMessages={setSuccessMessages}
        messages={successMessages}
      />
      <Container id="footer-container">
        <div className="footer-copyright">© 2021 - SGI CANADA</div>
        {loggedIn && showFeedbackButton && (
          <div
            className="footer-feedback"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              setModalShow(true);
            }}
          >
            Feedback
          </div>
        )}
        {loggedIn && showFeedbackButton && (
          <ModalFeedback
            show={modalShow}
            onHide={setModalShow}
            toastMessage={setToastMessage}
            accountType={accountType}
            email={email}
            name={name}
          />
        )}
        <div className="footer-privacy">Privacy Policy</div>
      </Container>
    </>
  );
}
