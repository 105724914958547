import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import "./Maps.css";
import { Address } from "../../types/Location";
import { parseAddressComponents } from "../../utils/VendorFinderUtils";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../../utils/AppInsights";
export function VendorSearch(props: any) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: new google.maps.LatLng(53.50774486995124, -105.95601868417705),
      radius: 750 * 1000,
    },
  });

  const handleInput = (e: any) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address: any) => {
    setValue(address, false);
    clearSuggestions();

    props.setErrorMessage("");

    var results = null;
    var latitude = 0;
    var longitude = 0;
    try {
      results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      latitude = lat;
      longitude = lng;
    } catch (error) {
      if (appInsights !== undefined) {
        appInsights.trackTrace({
          message:
            "Vendor Search - error retrieving location information: " + error,
          severityLevel: SeverityLevel.Error,
        });
      }
      console.log(
        "Vendor Search - error retrieving location information",
        error
      );
    }
    if (results && results[0]) {
      var addrComp = results[0].address_components;
      var addressData: Address = parseAddressComponents(addrComp);

      props.setShowInfo({ lat: 0, lng: 0, name: "" });

      props.setSelectedLocation({
        address: { ...addressData },
        formattedAddressFull: results[0].formatted_address,
        locationTypes: results[0].types,
        placeId: results[0].place_id,
        latitude: latitude,
        longitude: longitude,
        isSelectedByPin: true,
      });
    } else {
      props.setErrorMessage(
        "Invalid location. Please search a different location or click on map."
      );
    }
  };
  return (
    <div className="search">
      <div className="label">Location:</div>
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder={value !== "" ? value : "Search here or click on map"}
          className="form-control"
          data-testid={"VendorFinderSearchBox"}
        />

        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

export default VendorSearch;
