import "./VendorFinder.css";
import { useEffect, useState } from "react";
import { getAllVendorTypes } from "../../api/apiVendor";
import { Col, Form, Row } from "react-bootstrap";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { appInsights } from "../../utils/AppInsights";

export function VendorTypeSelector(props: any) {
  const [vendorTypes, setVendorTypes] = useState([""]);
  const [vendorTypesLoading, setVendorTypesLoading] = useState(true);

  useEffect(() => {
    var results: any = null;

    const fetchData = async () => {
      setVendorTypesLoading(true);
      try {
        results = await getAllVendorTypes();
      } catch (error) {
        if (appInsights !== undefined) {
          appInsights.trackTrace({
            message: "Error retrieving vendor types: " + error,
            severityLevel: SeverityLevel.Information,
          });
        }
        console.log("Error retrieving vendor types", error);
      }
      if (results) {
        setVendorTypes(results.data as string[]);
        props.setVendorTypeSelected((results.data as string[])[0]);
      }

      setVendorTypesLoading(false);
    };
    fetchData();
  }, []);

  const changeSelectedType = (e: any) => {
    props.setVendorTypeSelected(e.target.value);
  };

  const changeSelectedNumber = (e: any) => {
    props.setNumberOfVendors(e.target.value);
  };

  return (
    <>
      <div className="vendorTypeSelector">
        <Form>
          <Row>
            <Col xs={9}>
              <div className="label">Vendor Type:</div>
              <Form.Group>
                <Form.Control
                  as="select"
                  onChange={changeSelectedType}
                  data-testid="selectVendorType"
                >
                  {vendorTypesLoading && (
                    <option key="blankChoice" hidden value="">
                      Loading Vendor Types...
                    </option>
                  )}
                  {vendorTypes.sort().map((type) => {
                    if (type !== "")
                      return (
                        <option
                          key={type}
                          value={type}
                          data-testid="VendorTypeOption"
                        >
                          {type}
                        </option>
                      );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <div className="label">Nearest:</div>
              <Form.Group>
                <Form.Control
                  as="select"
                  onChange={changeSelectedNumber}
                  data-testid="selectVendorNumber"
                >
                  <option key={5} value={5} data-testid="VendorNumberOption">
                    5
                  </option>
                  <option key={10} value={10} data-testid="VendorNumberOption">
                    10
                  </option>
                  <option key={15} value={15} data-testid="VendorNumberOption">
                    15
                  </option>
                  <option key={20} value={20} data-testid="VendorNumberOption">
                    20
                  </option>
                  <option key={25} value={25} data-testid="VendorNumberOption">
                    25
                  </option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
