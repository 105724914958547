import { Address } from "../types/Location";

export function shuffleArray(arr: any[]) {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

const zoomLevels = [
  1128.49722, 2256.99444, 4513.98888, 9027.977761, 18055.95552, 36111.91104,
  72223.82209, 144447.6442, 288895.2884, 577790.5767, 1155581.153, 2311162.307,
  4622324.614, 9244649.227, 18489298.45, 36978596.91, 73957193.82, 147914387.6,
  295828775.3, 591657550.5,
];

export function getMapsZoomLevel(km: number) {
  let meters = km * 1000;
  for (var i = 0; i < 20; i++) {
    if (meters < zoomLevels[i]) {
      return 16 - (i + 1);
    }
  }
  return 6;
}
interface Icon {
  search: string;
  loc: string;
}

const iconLocations: Icon[] = [
  { search: "acupuncture", loc: "/vendorIcons/acupunctureIcon.svg" },
  { search: "assessment", loc: "/vendorIcons/assessmentIcon.svg" },
  { search: "chiro", loc: "/vendorIcons/chiropractorIcon.svg" },
  { search: "clinic", loc: "/vendorIcons/clinicIcon.svg" },
  { search: "dentist", loc: "/vendorIcons/dentistIcon.svg" },
  { search: "dentur", loc: "/vendorIcons/dentureIcon.svg" },
  { search: "hospital", loc: "/vendorIcons/hospitalIcon.svg" },
  { search: "massage", loc: "/vendorIcons/massageIcon.svg" },
  { search: "nurse", loc: "/vendorIcons/nurseIcon.svg" },
  { search: "optha", loc: "/vendorIcons/opthamIcon.svg" },
  { search: "optom", loc: "/vendorIcons/optometryIcon.svg" },
  { search: "physio", loc: "/vendorIcons/physioIcon.svg" },
  { search: "psychia", loc: "/vendorIcons/psychIcon.svg" },
  { search: "psychol", loc: "/vendorIcons/psychologyIcon.svg" },
  { search: "taxi", loc: "/vendorIcons/taxiIcon.svg" },
  { search: "tow", loc: "/vendorIcons/towIcon.svg" },
  { search: "treatment", loc: "/vendorIcons/treatmentIcon.svg" },
];

export function getIconUrl(vendorType: string): string {
  let type = vendorType.toLowerCase();

  var locationFound = iconLocations.filter((l) => {
    return type.includes(l.search);
  });

  if (locationFound[0]) return locationFound[0].loc;
  else return "/vendorIcons/vendorIcon.svg";
}

export function nullOrString(n: any): string | null {
  if (n) return n;
  return null;
}

export function parseAddressComponents(
  addrComp: google.maps.GeocoderAddressComponent[]
): Address {
  return {
    streetNumber: nullOrString(
      addrComp.find((s) => s.types.includes("street_number"))?.long_name
    ),
    street: nullOrString(
      addrComp.find((s) => s.types.includes("route"))?.long_name
    ),
    city: nullOrString(
      addrComp.find((s) => s.types.includes("locality"))?.long_name
    ),
    province: nullOrString(
      addrComp.find((s) => s.types.includes("administrative_area_level_1"))
        ?.short_name
    ),
    country: nullOrString(
      addrComp.find((s) => s.types.includes("country"))?.long_name
    ),
    postalCode: nullOrString(
      addrComp.find((s) => s.types.includes("postal_code"))?.long_name
    ),
  };
}
