import React from "react";
import "./App.css";
import { useTypedSelector } from "./redux/store";
import { Routes } from "react-router-dom";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "./theme.css";
import "./App.css";

import { routeProps } from "./types/RouteProps";
import PublicRoutes from "./Routes/PublicRoutes";
import PrivateInjuryRoutes from "./Routes/PrivateInjuryRoutes";

function App() {
  const reduxLogin = useTypedSelector((state) => state.login);

  var routeProps: routeProps = {
    username: reduxLogin.username,
    isAuthenticated: reduxLogin.signedIn,
    role: reduxLogin.role,
  };

  return (
    <>
      <Header />
      <Routes>
        {PrivateInjuryRoutes(routeProps)}
        {PublicRoutes(routeProps)}
      </Routes>
      <Footer />
    </>
  );
}

export default App;
