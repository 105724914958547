const initialState = {
  signedIn: false,
  username: "",
  role: undefined,
  loading: false,
  fullName: "",
  email: "",
};

const loginReducer = (
  state = initialState,
  action: { type: any; mockUser?: any }
) => {
  let newState = { ...state };
  switch (action.type) {
    case "USER_LOGIN": {
      newState.signedIn = true;
      newState.username = action.mockUser.username;
      newState.role = action.mockUser.role;
      newState.fullName = action.mockUser.fullName;
      newState.email = action.mockUser.email;
      return newState;
    }
    case "USER_LOGOUT": {
      return initialState;
    }
    default:
      return state;
  }
};

export default loginReducer;
