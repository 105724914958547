import { Route } from "react-router-dom";
import { Role } from "../types/MockedUserTypes";
import { routeProps } from "../types/RouteProps";
import VendorFinderWrapper from "../containers/VendorFinder/VendorFinderWrapper";

function PrivateInjuryRoutes(props: routeProps) {
  var returnRoutes: any[] = [];

  if (props.isAuthenticated && props.role === Role.Injury) {
    returnRoutes.push(
      <Route path="*" key="root" element={<VendorFinderWrapper />} />
    );
  }

  return returnRoutes;
}

export default PrivateInjuryRoutes;
