import React from "react";
import { Role } from "../types/MockedUserTypes";
import { Route, Link } from "react-router-dom";
import HomePage from "../containers/HomePage/HomePage";
import { routeProps } from "../types/RouteProps";

function PublicRoutes(props: routeProps) {
  var returnRoutes: any[] = [];

  if (!props.isAuthenticated || props.role !== Role.Injury) {
    returnRoutes.push(<Route path="*" key="root" element={<HomePage />} />);
    return returnRoutes;
  }
}

export default PublicRoutes;
