import MapFinder from "../../components/Maps/MapFinder";
import { useTypedSelector } from "../../redux/store";
import { Role } from "../../types/MockedUserTypes";
import { useLoadScript } from "@react-google-maps/api";
import "./VendorFinder.css";
import { useEffect, useState } from "react";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";
import { defaultNewLocation } from "../../types/Location";
import { Error } from "../../components/Alerts/Error";
import { getMapsZoomLevel, shuffleArray } from "../../utils/VendorFinderUtils";
import { VendorLocation, defaultVendorLocation } from "../../types/Vendor";
import { getNearestVendorLocations } from "../../api/apiVendor";
import { VendorLocationList } from "./VendorLocationList";
import { VendorSearch } from "../../components/Maps/VendorSearch";
import { VendorTypeSelector } from "./VendorTypeSelector";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../../utils/AppInsights";

export function VendorFinder(props: any) {
  const login = useTypedSelector((state) => state.login);
  const [selectedLocation, setSelectedLocation] = useState(defaultNewLocation);
  const [vendorLocationList, setVendorLocationList] = useState<
    VendorLocation[]
  >([]);
  const [vendorLocationsLoading, setVendorLocationsLoading] = useState(false);
  const [vendorTypeSelected, setVendorTypeSelected] = useState("");
  const [numberOfVendors, setNumberOfVendors] = useState(5);

  const [errorMessage, setErrorMessage] = useState("");
  const [libraries]: any = useState(["places"]);
  const [zoomMap, setZoomMap] = useState(10);
  const [showInfo, setShowInfo] = useState(defaultVendorLocation);

  const { isLoaded, loadError } = useLoadScript({
    libraries,
    googleMapsApiKey: props.mapsApiKey,
  });

  useEffect(() => {
    var results: any = null;

    const fetchData = async () => {
      setVendorLocationsLoading(true);
      setVendorLocationList([]);
      try {
        results = await getNearestVendorLocations(
          {
            lat: selectedLocation.latitude,
            lng: selectedLocation.longitude,
          },
          vendorTypeSelected,
          numberOfVendors
        );
      } catch (error) {
        if (appInsights !== undefined) {
          appInsights.trackTrace({
            message: "Error retrieving vendor locations: " + error,
            severityLevel: SeverityLevel.Error,
          });
        }
        console.log("Error retrieving vendor locations", error);
      }
      if (results) {
        var vendorLocations = results.data as VendorLocation[];

        setVendorLocationList(shuffleArray([...vendorLocations]));
      }
      setVendorLocationsLoading(false);
    };
    if (selectedLocation.longitude !== 0) fetchData();
  }, [selectedLocation, vendorTypeSelected, numberOfVendors]);

  useEffect(() => {
    if (vendorLocationList.length > 0) {
      var v = getMapsZoomLevel(
        vendorLocationList.reduce((p, c) => (p.distance > c.distance ? p : c))
          .distance
      );
      setZoomMap(v);
    }
  }, [vendorLocationList]);

  if (loadError) {
    return <>Error Loading Google Maps</>;
  } else if (!isLoaded) return <PageSpinner />;
  else
    return (
      <>
        {login.signedIn === true &&
          (login.role === Role.Employee || login.role === Role.Injury) && (
            <>
              <div id="FinderContainer">
                <div id="MapContainer">
                  <div id="VendorListContainer">
                    <VendorTypeSelector
                      vendorTypeSelected={vendorTypeSelected}
                      setVendorTypeSelected={setVendorTypeSelected}
                      setNumberOfVendors={setNumberOfVendors}
                    />
                    <VendorSearch
                      selectedLocation={selectedLocation}
                      setSelectedLocation={setSelectedLocation}
                      setErrorMessage={setErrorMessage}
                      setShowInfo={setShowInfo}
                    />
                    <VendorLocationList
                      vendorLocationList={vendorLocationList}
                      vendorLocationsLoading={vendorLocationsLoading}
                      vendorTypeSelected={vendorTypeSelected}
                      showInfo={showInfo}
                      setShowInfo={setShowInfo}
                    />
                  </div>
                  <div id="MapWrapper">
                    <MapFinder
                      selectedLocation={selectedLocation}
                      setSelectedLocation={setSelectedLocation}
                      setErrorMessage={setErrorMessage}
                      vendorLocationList={vendorLocationList}
                      vendorTypeSelected={vendorTypeSelected}
                      zoomMap={zoomMap}
                      showInfo={showInfo}
                      setShowInfo={setShowInfo}
                    />
                    {errorMessage !== "" && <Error message={errorMessage} />}
                  </div>
                </div>
              </div>
            </>
          )}
      </>
    );
}
