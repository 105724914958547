export interface MockUser {
  username: string;
  role: Role;
  fullName: string;
  email: string;
}

export enum Role {
  Vendor = "Vendor",
  Employee = "Employee",
  Injury = "Injury",
  None = "None",
}
