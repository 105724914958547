import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Button, Spinner } from "react-bootstrap";
import "./Header.css";
import logo from "../../assets/sgi_logo.png";
import { useNavigate } from "react-router-dom";
import { useTypedSelector } from "../../redux/store";
import { HeaderDropDownButton } from "./HeaderDropDownButton";
import { MockUser, Role } from "../../types/MockedUserTypes";
import { useMsal } from "@azure/msal-react";
import {
  forgotPasswordRequest,
  loginRequest,
} from "../../utils/auth/authConfig";
import { getAccountByEmail } from "../../api/apiAccount";
import { login } from "../../redux/actions/loginActions";
import { useDispatch } from "react-redux";
import { clearRedirect } from "../../redux/actions/redirectActions";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from "@azure/msal-browser";
import { getMapsAPIKey } from "../../api/apiMaps";
import { AxiosResponse } from "axios";
import { ToastErrors } from "../Toast/ToastErrors";
import { appInsights } from "../../utils/AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";

function isAuthenticated(reduxLogin: any): boolean {
  return reduxLogin.signedIn;
}

export function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const redirect = useTypedSelector((state) => state.redirect);
  const reduxLogin = useTypedSelector((state) => state.login);

  const emptyStringArray: string[] = [];
  const [infoMessages, setInfoMessages] = useState(emptyStringArray);
  const [errorMessages, setErrorMessages] = useState(emptyStringArray);

  const [displaySpinner, setDisplaySpinner] = useState(false);

  useEffect(() => {
    async function checkActionRequestItem() {
      let info = [];
      if (isAuthenticated(reduxLogin) === true && appInsights !== undefined) {
        appInsights.trackEvent({ name: reduxLogin.role + " login" });
        appInsights.trackTrace({
          message: "Successful login by " + reduxLogin.email,
          severityLevel: SeverityLevel.Information,
        });
      }
    }
    checkActionRequestItem();
  }, [reduxLogin, reduxLogin.role, reduxLogin.username]);

  // auth declares
  const { instance } = useMsal();

  // AAD login
  const handleLogin = () => {
    try {
      instance
        .loginRedirect(loginRequest)
        .catch((e) => {
          console.log("Error with authentication: " + e);
        })
        .then((response) => {
          console.log("Login successful" + response);
        });
    } catch (err) {
      console.error(err);
    }
  };

  async function assignSecurity(payload: any) {
    if (payload) {
      // get email and check the user's security
      var email: string = payload.idTokenClaims.email;
      var response = await getAccountByEmail(email);

      if (response !== undefined) {
        var email: string = response.data.email;

        var role: Role = Role.None;

        if (response.data.accountType === "SGIEmployee") {
          role = Role.Employee;
        } else if (response.data.accountType === "Injury") {
          role = Role.Injury;
        }

        const loginPackage: MockUser = {
          username: payload.idTokenClaims.name,
          role: role,
          fullName:
            payload.idTokenClaims.given_name +
            " " +
            payload.idTokenClaims.family_name,
          email: email,
        };

        // set our login state object
        dispatch(login(loginPackage));

        if (role === Role.Injury) {
          navigate("/");
        }
      } else {
        const loginPackage: MockUser = {
          username: payload.idTokenClaims.name,
          role: Role.None,
          fullName:
            payload.idTokenClaims.given_name +
            " " +
            payload.idTokenClaims.family_name,
          email: email,
        };

        // set our login state object
        dispatch(login(loginPackage));

        if (isAuthenticated(reduxLogin) === true && appInsights !== undefined) {
          appInsights.trackTrace({
            message: "Login with unauthorized account by " + reduxLogin.email,
            severityLevel: SeverityLevel.Warning,
          });
        }

        setErrorMessages([...errorMessages, "This account is not authorized."]);
      }
    }

    setDisplaySpinner(false);
  }

  useEffect(() => {
    const callbackId = instance.addEventCallback(
      async (message: EventMessage) => {
        // Update UI or interact with EventMessage here
        if (
          message.eventType === EventType.LOGIN_SUCCESS ||
          message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ) {
          setDisplaySpinner(true);
          const payload: any = message.payload;
          if (
            payload.idTokenClaims.acr === "b2c_1a_vendorpilot_passwordreset"
          ) {
            instance.logout();
            return;
          }
          assignSecurity(message.payload);

          let apiKey: AxiosResponse<any> = await getMapsAPIKey();
          (window as any)["apiConfig"] = {
            googleMapsApiData: apiKey.data,
          };
        }

        if (message.eventType === EventType.LOGIN_FAILURE) {
          console.log(message);
          // The user has requested a password change
          if (
            message.error?.message.includes(
              "The user has forgotten their password"
            )
          ) {
            instance.loginRedirect(forgotPasswordRequest());
          } else {
            setErrorMessages([
              ...errorMessages,
              "The sign-on action could not be completed.",
            ]);
          }
        }

        const authResult = message.payload as AuthenticationResult;

        if (
          message.eventType === EventType.LOGIN_SUCCESS &&
          authResult.account
        ) {
          const account = authResult.account;
          instance.setActiveAccount(account);
        }
      }
    );
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  return (
    <>
      <Container id="header-container" className="fixed-top">
        <ToastErrors
          title="Auth Errors"
          setMessages={setErrorMessages}
          messages={errorMessages}
        />

        <div className="header-logo" data-testid="header-logo">
          <img
            src={logo}
            alt="SGI logo"
            onClick={(e) => {
              navigate("/");
            }}
          />
        </div>
        <div className="header-title" onClick={() => navigate("/")}>
          SGI Vendor Finder
        </div>
        <div className="header-login">
          {isAuthenticated(reduxLogin) === false ? (
            <>
              <Button
                className="header-btn"
                variant="outline-primary"
                id="HeaderLoginButton"
                data-testid="HeaderLoginButton"
                onClick={handleLogin}
                disabled={displaySpinner}
              >
                {displaySpinner && (
                  <Spinner
                    id="loginSpinner"
                    animation="border"
                    role="status"
                    className="spinner"
                  />
                )}
                {!displaySpinner && "Login"}
              </Button>
            </>
          ) : (
            <HeaderDropDownButton />
          )}
        </div>
      </Container>
      <div className="HeaderSpacer"></div>
    </>
  );
}
