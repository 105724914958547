import { useEffect, useState } from "react";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";
import { getMapsAPIKey } from "../../api/apiMaps";
import { VendorFinder } from "./VendorFinder";
import { AxiosResponse } from "axios";

export function VendorFinderWrapper(props: any) {
  const [mapsApiKeyloading, setMapsApiKeyloading] = useState(true);
  const [mapsApiKey, setMapsApiKey] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      let apiKey: AxiosResponse<any> = await getMapsAPIKey();
      (window as any)["apiConfig"] = {
        googleMapsApiData: apiKey.data,
      };
      setMapsApiKey(apiKey.data);
      setMapsApiKeyloading(false);
    };
    fetchData();
  }, []);

  if (mapsApiKeyloading) {
    return <PageSpinner />;
  } else return <VendorFinder mapsApiKey={mapsApiKey} />;
}

export default VendorFinderWrapper;
