import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";

export async function getNearestVendorLocations(
  coordinates: any,
  vendorType: string,
  count: number
) {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .get(baseUrl + "Vendor/GetNearestVendors/", {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      params: {
        lat: coordinates.lat,
        lng: coordinates.lng,
        vendorType: vendorType,
        count: count,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getAllVendorTypes() {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();
  return axios
    .get(baseUrl + "Vendor/GetAllVendorTypes", {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}
