import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";

export async function getMapsAPIKey() {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();
  //return { data: "AIzaSyAV-TjnGR2LZTsC8FM0pJbR-SaBisoDY6c" };
  return axios
    .get(baseUrl + "Maps/mapkey", {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}
