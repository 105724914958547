import loginReducer from "./loginReducer";
import { combineReducers } from "redux";
import redirectReducer from "./redirectReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  redirect: redirectReducer,
  // Add reducers here
  // key: reducer you've imported at the top.
});

export default rootReducer;
