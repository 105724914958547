import { Container } from "react-bootstrap";
import "./HomePage.css";

function HomePage() {
  return (
    <Container id="HomePageContainer">
      <div id="WelcomeMessage">Welcome to the SGI Vendor Finder</div>
    </Container>
  );
}

export default HomePage;
