export interface VendorLocation {
  name: string;
  city: string;
  postalCode: string;
  streetAddress: string;
  phoneNumber: string;
  emailAddress: string;
  vendorTypes: string[];
  lat: number;
  lng: number;
  distance: number;
}

export const defaultVendorLocation: VendorLocation = {
  name: "",
  city: "",
  postalCode: "",
  streetAddress: "",
  phoneNumber: "",
  emailAddress: "",
  vendorTypes: [],
  lat: 0,
  lng: 0,
  distance: 0,
};
