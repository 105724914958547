import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { appInsights } from "../AppInsights";
import { getConfig, getScopes } from "./authConfig";

export async function getAccessToken() {
  var msalInstance = new PublicClientApplication(getConfig());
  const accounts = msalInstance.getAllAccounts();
  if (accounts !== undefined) {
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }
  }

  const account = msalInstance.getActiveAccount();
  if (!account) {
    return "";
  }
  let authResult;
  try {
    authResult = await msalInstance.acquireTokenSilent({
      account: account,
      scopes: getScopes(),
    });
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      try {
        authResult = await msalInstance.acquireTokenPopup({
          account: account,
          scopes: getScopes(),
        });
      } catch (popuperror) {
        if (appInsights !== undefined) {
          appInsights.trackTrace({
            message: "Error with interactive token: " + popuperror,
            severityLevel: SeverityLevel.Information,
          });
        }
        console.log("Error with interactive token: ", popuperror);
      }
    }
  }

  return authResult?.accessToken;
}
