import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../utils/AppInsights";

export async function handleResponse(response: any) {
  if (response.status >= 200 && response.status < 300) return response;
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    if (appInsights !== undefined) {
      appInsights.trackTrace({
        message: "Server-side error: " + error,
        severityLevel: SeverityLevel.Error,
      });
    }
    console.error(error);
    return response;
  }
  if (response.status === 404) {
    return "Not Found";
  }
  if (appInsights !== undefined) {
    appInsights.trackTrace({
      message: "Network response was not ok: " + response,
      severityLevel: SeverityLevel.Error,
    });
  }
  console.error("Network response was not ok.");
  return response;
}

// In a real app, would likely call an error logging service.
export function handleError(error: any) {
  // eslint-disable-next-line no-console
  if (appInsights !== undefined) {
    appInsights.trackTrace({
      message: "API call failed: " + error,
      severityLevel: SeverityLevel.Error,
    });
  }
  console.error("API call failed. " + error);
}

const apiUtils = { handleResponse, handleError };
export default apiUtils;
