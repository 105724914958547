import "./VendorFinder.css";
import { PageSpinnerComponent } from "../../components/PageSpinner/PageSpinner";
import { Table } from "react-bootstrap";
import { VendorLocation } from "../../types/Vendor";
import { formatPhoneNumber } from "../../utils/Utils";

type SetShowInfoType = React.Dispatch<React.SetStateAction<VendorLocation>>;

type ShowInfoType = { lat: number; lng: number; name: string };

export function VendorLocationList(props: {
  vendorLocationList: VendorLocation[];
  vendorLocationsLoading: boolean;
  vendorTypeSelected: string;
  showInfo: ShowInfoType;
  setShowInfo: SetShowInfoType;
}) {
  return (
    <div id="VendorList">
      {props.vendorLocationList &&
      props.vendorLocationList.length > 0 &&
      !props.vendorLocationsLoading ? (
        <div id="tableWrapper">
          <Table striped bordered hover id="VendorLocationsTable">
            <thead>
              <tr key="title">
                <th>Distance</th>
                <th>Vendor Details</th>
              </tr>
            </thead>
            <tbody key="tbody">
              {props.vendorLocationList.map((l) =>
                renderVendorRow(
                  l,
                  props.showInfo,
                  props.setShowInfo,
                  props.vendorTypeSelected
                )
              )}
            </tbody>
          </Table>
        </div>
      ) : (
        <Table striped bordered hover id="VendorLocationsTable">
          <thead>
            <tr key="title">
              <th>Distance</th>
              <th>Vendor Details</th>
            </tr>
          </thead>
          <tbody key="tbody">
            {props.vendorLocationsLoading ? (
              <>
                <p></p>
                <PageSpinnerComponent />
                <p></p>
              </>
            ) : (
              <div id="EmptyTableMessage">
                Type a location above or click on map to find nearby vendors
              </div>
            )}
          </tbody>
        </Table>
      )}
    </div>
  );
}

function renderVendorRow(
  loc: VendorLocation,
  showInfo: ShowInfoType,
  setShowInfo: SetShowInfoType,
  vendorTypeSelected: string
) {
  return (
    <tr
      key={loc.name}
      className={showInfo.name === loc.name ? "tableSelectedRow" : ""}
      data-testid={"vendorLocationListRow"}
      onClick={() => setShowInfo(loc)}
    >
      <td>{loc.distance.toFixed(1)} km</td>
      <td>
        <div className="vendorListTitle">{loc.name.toLowerCase()}</div>
        {showInfo.name === loc.name ? (
          <>
            <div className="vendorListAddress">
              {loc.streetAddress.toLowerCase()}
              <div>
                {loc.postalCode}
                {", "} {loc.city.toLowerCase()}
              </div>
            </div>
            <div className="vendorListContact">
              <div>
                {loc.phoneNumber &&
                  loc.phoneNumber !== "" &&
                  formatPhoneNumber(loc.phoneNumber)}
              </div>
              <div>
                {loc.emailAddress &&
                  loc.emailAddress !== "" &&
                  loc.emailAddress}
              </div>
              <div>
                {loc.vendorTypes.length > 1 &&
                  "Other Services: " +
                    loc.vendorTypes
                      .filter((t) => {
                        return t != vendorTypeSelected;
                      })
                      .join(", ")}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </td>
    </tr>
  );
}
